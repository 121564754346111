<template>
  <div class="yj-frame-main">
    <div class="up-banner">
      <h1>公司介绍</h1>
      <img src="../../assets/image/about/1.png" alt="">
    </div>
    <div class="qyhxys" style="height:580px">
      <h1>公司简介</h1>
      <div class="cpjj">
        <div class="left">
          <div class="textCon">
            <p>江苏禹江科技有限公司（简称“禹江科技”），注册资本5000万元，位于江苏省南京市鼓楼区。公司专注于北斗卫星技术在各行业领域的应用，重点以“北斗+5G+人工智能”技术应用整合为基础，在智慧物流、智慧工地、智慧工厂、智慧园区领域实施科技创新研发和应用推广，主要业务包括智慧物流体系物联网设备研发应用和信息化软件研发应用、工业企业智能管理系统的研发和应用、园区社区智能管理系统的研发和应用等。</p>
            <p>2020-2021年，禹江科技作为江苏省发改委智慧物流降本增效改革关键技术突破试点单位。江苏禹新航运科技有限公司与武汉易江信息科技有限公司同属于江苏禹江科技有限公司的独资企业。</p>
          </div>
        </div>
        <div class="right">
          <img src="../../assets/image/about/2.png">
        </div>
      </div>
    </div>
    <div class="otherback">
      <div class="qyhxys" style="height:530px;">
      <h1>研发实力</h1>
      <div class="yfsl">
        <div class="botCon">
          <div class="item">
            <img src="../../assets/image/about/6.png">
            <h5>应用软件开发</h5>
          </div>
          <div class="item">
            <img src="../../assets/image/about/7.png">
            <h5>数据治理应用</h5>
          </div>
          <div class="item">
            <img src="../../assets/image/about/8.png">
            <h5>智能硬件开发</h5>
          </div>
          <div class="item">
            <img src="../../assets/image/about/9.png">
            <h5>智能算法开发</h5>
          </div>
          <div class="item">
            <img src="../../assets/image/about/10.png">
            <h5>行业课题研究</h5>
          </div>
          <div class="item">
            <img src="../../assets/image/about/11.png">
            <h5>解决方案设计</h5>
          </div>
          <div class="item">
            <img src="../../assets/image/about/12.png">
            <h5>信息系统集成</h5>
          </div>
          <div class="item">
            <img src="../../assets/image/about/13.png">
            <h5>行业论坛举办</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="FFFback">
    <div class="qyhxys" style="height:700px;">
      <h1>研发成果</h1>
      <el-carousel :interval="3000" type="card" height="550px">
        <el-carousel-item v-for="item in cgList" :key="item">
          <img :src="item" /> 
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: "introduce",
  data(){
    return {
      tabPosition: 'right',
      cgList:[
        require("@/assets/image/about/001.png"),
        require("@/assets/image/about/002.png"),
        require("@/assets/image/about/003.png"),
        require("@/assets/image/about/004.png"),
        require("@/assets/image/about/005.png"),
        // require("@/assets/image/about/006.png"),  // 下架
        require("@/assets/image/about/007.png"),
        require("@/assets/image/about/008.png"),
        require("@/assets/image/about/009.png"),
        require("@/assets/image/about/010.png"),
      ],
    }
  },
  mounted() {

  },
  methods: {
   
  },
}
</script>
<style lang="scss" scoped>
.cpjj{
  border-radius: 10px;
  height: 330px;
   .left{
    padding-right: 3.5rem;
    width: 40%;
  }
  .textCon{
    margin-top: 1rem;
    p{
      text-indent: 32px;
    }
  }
  .right{
    img{
      width: 480px;
      margin-left: 3.75rem;
      margin-top: 1.5rem;
    }
  }
}
.otherback{
  background:url("~@/assets/image/about/5.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.yfsl{
  background: #FFFFFF;
  width: 1140px;
  height: 300px;
  padding: 30px;
  border-radius: 10px;
  .botCon{
    width: 100%;
    height: 100%;
    .item{
      border-bottom: 1px solid #dde6ff;
      border-right: 1px solid #dde6ff;
      width: 283.5px;
      height: 148px;
      float: left;
      text-align: center;
      img{
        display: block;
        margin: 0 auto;
        width: 40px;
        margin-top: 40px;
      }
      h5{
        margin-top: 20px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 2px;
      }
    }
    .item:nth-child(5), .item:nth-child(6), .item:nth-child(7), .item:nth-child(8) {
      border-bottom: 0 none;
    }
    .item:nth-child(4),.item:nth-child(8) {
      border-right: 0 none;
    }
  }
}
.el-carousel{
  display: block;
  text-align: center;
}
</style>
